.app {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10px;
  flex-grow: 1;
  max-width: 500px;
  width: 100%;
}

.input-wrapper {
  max-width: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 40px;
}

.input-label {
  font-weight: bold;
  flex-shrink: 0;
  margin-right: 10px;
}

.logo {
  max-width: 500px;
  width: 100%;
}

.tip {
  margin-bottom: 20px;
}

.tip > a {
  color: inherit;
}

.status {
  height: 50px;
  margin-top: 20px;
}
